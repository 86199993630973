import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: '',
    WBlink: 'https://www.wildberries.ru/catalog/0/search.aspx?xfilters=xsubject%3Bdlvr%3Bbrand%3Bprice%3Bkind%3Bcolor%3Bwbsize%3Bseason%3Bconsists&xparams=brand%3D137438%26subject%3D814&xshard=brands%2Fb&sort=popular&search=Black+Rabbit+%2F+%D0%9F%D0%BE%D0%B4%D0%B3%D1%83%D0%B7%D0%BD%D0%B8%D0%BA%D0%B8+%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B8%D0%B5&targetUrl=XS',

    sum: 0,
    productsInCart: 0,

    isDiapersActive: true,

    list: {},

    items: {},

    cart: {},
    cartSum: 0,

    isLogoHidden: true,
  },
  mutations: {
    setWBlink(state, link) {
      state.WBlink = link
    },

    changeList(state, data) {
      state.sum += data.price
      state.list[data.sku] = data
    },

    switchProduct(state) {
      state.isDiapersActive = !state.isDiapersActive
    },

    changeLogoVisibility(state, data) {
      state.isLogoHidden = data.param
    },

    setItems(state, data) {
      state.items = data
    },

    calcSum(state) {
      state.sum = state.cartSum
    },

    recalc(state) {
      state.cartSum = 0

      Object.values(state.cart).forEach(item => {
        state.cartSum += item.price * item.amount
      })
    },

    changeCartItemAmount(state, data) {
      if (state.cart[data.sku] == undefined) {
        state.cart[data.sku] = data
      }

      state.cart[data.sku].amount = data.amount

      if (data.amount == 0) {
        delete state.cart[data.sku]
      }

      state.cartSum = 0

      Object.values(state.cart).forEach(item => {
        state.cartSum += item.price * item.amount
      })

      state.productsInCart = Object.keys(state.cart).length
    },

  },
  actions: {
    recalcCart({commit}, payload) {
      commit('changeCartItemAmount', payload)

    }
  },
  modules: {
  }
})
