<template>
  <div class="control">
    <div class="control__price" v-if="0">
      <span class="control__price-original" :class="{'is-lined': params.price != params.price_with_discount}">{{ params.price }}p.</span>
      <div v-if="params.price != params.price_with_discount">{{ params.price_with_discount }}p.</div>
    </div>

    <div class="control__size">{{ params.size }}</div>

    <div class="control__descr">
      {{ params.weight }}
    </div>
    <div class="control__counter" :class="{'is-active': isActive}">
      <!--<div @click="minus" class="control__minus">-</div>-->
      <div @click="changeActiveStatus" class="control__counter-circle"></div>
      <!--<div @click="plus" class="control__plus">+</div>-->
    </div>

    <div class="control__amount">{{ params.amount }}</div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: "Control",

    data() {
      return {
        amount: 0,
        isActive: false,
      }
    },

    props: {
      params: {

      }
    },

    computed: {
      ...mapState([
        'cart', 'WBlink'
      ]),
    },

    methods: {
      ...mapMutations([
        'changeList', 'addToCart', 'changeCartItemAmount', 'setWBlink'
      ]),

      plus() {
        this.amount++
        this.sendData(false)

        window.dataLayer.push({
          'event':'brshop',
          'eventCategory':'order',
          'eventAction':'basket',
          'eventLabel':'add goods',
          'goodsType': this.params.name,
          'goodsSize': this.params.size,
          'price': this.params.price
        });
      },
      minus() {
        if (this.amount > 0) {
          this.amount--
          this.sendData(true)

          if (this.amount == 0) {
            this.isActive = false
          }

          window.dataLayer.push({
            'event':'brshop',
            'eventCategory':'order',
            'eventAction':'basket',
            'eventLabel':'delete goods',
            'goodsType': this.params.name,
            'goodsSize': this.params.size,
            'price': this.params.price
          });
        }
      },

      changeActiveStatus() {
        this.isActive = !this.isActive
        //this.plus()
        this.setWBlink(this.params.link)

      },

      sendData(minus) {
        const data = Object.assign({}, this.params)

        data.amount = this.amount
        data.price = data.price_with_discount

        if (minus) {
          data.price = -data.price
        }
        this.changeList(data)


        this.changeCartItemAmount(data)
      }
    },

    mounted() {


      if (this.cart[this.params.sku] != undefined) {
        this.amount = this.cart[this.params.sku].amount
        this.isActive = true
      }
    }
  }
</script>

<style scoped>

</style>
