<template>
  <div>
    <div class="cart-widget">

      <div v-click-outside="hidePopup" class="cart-widget__popup" :class="{'is-visible': popup}">
        Если у вас возникли вопросы, напишите нам на
        <a href="mailto:hello@black-rabbit.us">hello@black-rabbit.us</a>
      </div>

      <div class="cart-widget__wrapper">
        <div v-scroll-to="'#screen-1'" class="cart-widget__logo img-w" :class="{'is-hidden': isLogoHidden}">
          <img src="@/assets/img/logo.png" alt="">
        </div>

        <div @click="togglePopup" class="cart-widget__env img-w">
          <svg viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M21.69 1.23V14.96H1.23V1.23H21.69ZM21.84 0H1.08C0.48 0 0 0.48 0 1.08V15.11C0 15.71 0.48 16.19 1.08 16.19H21.84C22.44 16.19 22.92 15.71 22.92 15.11V1.08C22.92 0.48 22.43 0 21.84 0Z" fill="#90D2E6"/>
              <path d="M0.689941 3.88C0.689941 3.88 11.0799 12.3 11.5199 12.3C11.9499 12.3 22.2199 3.88 22.2199 3.88" stroke="#90D2E6" stroke-width="1.23" stroke-miterlimit="10"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="22.92" height="16.19" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>

        <div @click="click" class="cart-widget__cart" v-if="0">
          <div class="cart-widget__ico img-w">
            <svg viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.0091 19.9924L16.4273 5.0306C16.3941 4.71738 16.1283 4.47955 15.8113 4.47955H12.8383V3.80627C12.8383 1.70757 11.1192 0 9.0063 0C6.89327 0 5.17417 1.70757 5.17417 3.80627V4.47955H2.20118C1.8841 4.47955 1.61831 4.71738 1.58512 5.0306L0.00336293 19.9924C-0.0148967 20.1658 0.0417875 20.3388 0.159125 20.4684C0.276622 20.5979 0.443975 20.6719 0.619426 20.6719H17.3929C17.5685 20.6719 17.7358 20.5979 17.8532 20.4684C17.9708 20.3388 18.0273 20.1658 18.0091 19.9924ZM6.41296 3.80627C6.41296 2.38605 7.57634 1.23048 9.0063 1.23048C10.4361 1.23048 11.5995 2.38605 11.5995 3.80627V4.47955H6.41296V3.80627ZM1.30726 19.4414L2.75898 5.71003H5.17417V7.0659C5.17417 7.40561 5.45155 7.68114 5.79356 7.68114C6.13557 7.68114 6.41296 7.40561 6.41296 7.0659V5.71003H11.5995V7.0659C11.5995 7.40561 11.8769 7.68114 12.2189 7.68114C12.5609 7.68114 12.8383 7.40561 12.8383 7.0659V5.71003H15.2535L16.7052 19.4414H1.30726Z" fill="#8CDBED"/>
            </svg>
          </div>
          <div class="cart-widget__amount">
            {{ productsInCart }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: "CartWidget",

    data() {
      return {
        popup: false,

        isClicked: false,
      }
    },

    computed: {
      ...mapState([
        'productsInCart', 'isLogoHidden',
      ]),
    },

    methods: {
      click() {
        if (this.productsInCart == 0) {
          this.$scrollTo('#store')
        } else {
          this.$router.push('/cart')
        }
      },

      hidePopup() {
        if (this.isClicked) {
          this.popup = false
          this.isClicked = false
        }
      },

      togglePopup() {
        if (this.popup) {
          this.isClicked = false
        } else {
          setTimeout(() => {
            this.isClicked = true
          }, 200)
        }

        this.popup = !this.popup

      }
    }
  }
</script>

<style scoped>

</style>
