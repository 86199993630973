<template>
    <span class="toggle-wrapper" @click="toggle" role="checkbox" :aria-checked="value.toString()" tabindex="0" @keydown.space.prevent="toggle">
      <span class="toggle-background" :style="backgroundStyles"></span>
      <span class="toggle-indicator" :style="indicatorStyles"></span>
    </span>
</template>

<script>
    export default {
        name: "Toggle",
        props: ['value'],
        methods: {
            toggle() {
                this.$emit('input', !this.value)
            }
        },
        computed: {
            backgroundStyles() {
                return {
                    backgroundColor: this.value ? 'transparent' : 'transparent'
                }
            },
            indicatorStyles() {
                return {transform: this.value ? 'translateX(2rem)' : 'translateX(0)'}
            }
        },
    }
</script>

<style scoped>
    .toggle-wrapper {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 2rem;
        width: 4rem;
        border-radius: 9999px;
        transform: scale(1.3);
    }
    .toggle-wrapper:focus {
        outline: 0;
    }

    .toggle-background {
        display: inline-block;
        border-radius: 9999px;
        height: 100%;
        width: 100%;
        border: 1px solid white;
        transition: background-color .2s ease;
    }

    .toggle-indicator {
        position: absolute;
        top: .25rem;
        left: .25rem;
        height: 1.5rem;
        width: 1.5rem;
        background-color: #8CDBED;
        border: 1px solid white;
        border-radius: 9999px;
        transition: transform .2s ease;
    }
</style>