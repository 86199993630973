<template>
  <div class="store__block">
    <div class="store__wrapper">
      <div class="product">

        <div class="product__preload">
          <img alt="" src="@/assets/img/animation/1.png">
          <img alt="" src="@/assets/img/animation/2.png">
          <img alt="" src="@/assets/img/animation/3.png">
          <img alt="" src="@/assets/img/animation/4.png">
          <img alt="" src="@/assets/img/animation/5.png">
          <img alt="" src="@/assets/img/animation/6.png">
        </div>

        <div class="product__img img-w">
          <img alt="" :src="require(`../assets/img/animation/${this.packAnimationNumber}.png`)">
        </div>

        <div class="product__switch">
          <span class="product__switch-label">подгузники</span>
          <Toggle v-model="productPanties"/>
          <span class="product__switch-label">трусики</span>
        </div>

        <div class="product__price">
          &nbsp;
        </div>

        <div class="product__controls" v-show="!productPanties">
          <Control v-for="item in diapers"
                   :key="item.id"
                   :params="item"></Control>
        </div>

        <div class="product__controls" v-show="productPanties">
          <Control v-for="item in panties"
                   :key="item.id"
                   :params="item"></Control>
        </div>

        <div class="product__line"></div>
      </div>

    </div>


    <div class="store__bottom">

      <div class="store__sum">
        <div @click="goToWB" class="store__sum-button">
          ЗАКАЗАТЬ
        </div>
      </div>

    </div>

    <div class="store__delivery" v-if="0">
      Бесплатная доставка по Москве от 2-х коробок и первого заказа.
    </div>

    <div @click="toggleDelivery" class="store__info" v-if="0">
      Подробнее о доставке
    </div>

    <div class="delivery" :class="{'is-visible': delivery}">
      <div @click="toggleDelivery" class="delivery__close img-w">
        <img src="@/assets/img/close.png" alt="">
      </div>

      <div class="delivery__title">ЗАКАЗ <br>
        И ДОСТАВКА</div>

      <p class="delivery__text">
        Мы <b>бесплатно доставим</b> по Москве Ваш <b>первый заказ</b> на подгузники или подгрузники- трусики Black Rabbit.
      </p>
      <p class="delivery__text">
        Уверены, что Вы сразу станете нашим постоянным клиентом, ведь подгузники Black Rabbit действительно разительно отличаются от всех остальных.
      </p>
      <p class="delivery__text">
        Мы готовы предложить Вам на второй и все последующие заказы <b>бесплатную доставку</b> по Москве от <b>2-х коробок</b>. Если ваш заказ меньше, то согласуем стоимость доставки удобной логистической службой на этапе его оформления.
      </p>

      <p class="delivery__note delivery__note--first">
        В самое ближайшее время подгузники и трусики Black Rabbit будут доступны на
        <b>Ozon</b> и <b>Wildberries</b>.
      </p>
      <p class="delivery__note">
        Надеемся, это очень упростит процесс
        покупки и доставки в регионы.
      </p>
    </div>
  </div>

</template>

<script>
  import Control from '@/components/Control.vue'
  import { mapState, mapMutations } from 'vuex'
  import Toggle from '@/components/Toggle'
  import axios from 'axios'

  export default {
    name: "Store",

    data() {
      return {
        panties: [{
          "sku": "diaper,6-11,M,32,11.20",
          "name": "Трусики",
          "size": "M",
          "weight": "6-11 кг",
          "amount": "32 штуки",
          "discount": 0,
          "price": 1950,
          "price_with_discount": 1950,
          "count": 1,
          link: 'https://www.wildberries.ru/catalog/17996204/detail.aspx?targetUrl=ES',
        },
          {
            "sku": "diaper,6-11,M,32,11.20",
            "name": "Трусики",
            "size": "L",
            "weight": "9-14 кг",
            "amount": "32 штуки",
            "discount": 0,
            "price": 1950,
            "price_with_discount": 1950,
            "count": 1,
            link: 'https://www.wildberries.ru/catalog/17996224/detail.aspx?targetUrl=ES'
          },
          {
            "sku": "diaper,6-11,M,32,11.20",
            "name": "Трусики",
            "size": "XL",
            "weight": "12-22 кг",
            "amount": "32 штуки",
            "discount": 0,
            "price": 1950,
            "price_with_discount": 1950,
            "count": 1,
            link: 'https://www.wildberries.ru/catalog/17996231/detail.aspx?targetUrl=ES'
          },
          {
            "sku": "diaper,6-11,M,32,11.20",
            "name": "Трусики",
            "size": "XXL",
            "weight": "15-28 кг",
            "amount": "26 штук",
            "discount": 0,
            "price": 1950,
            "price_with_discount": 1950,
            "count": 1,
            link: 'https://www.wildberries.ru/catalog/17996240/detail.aspx?targetUrl=ES'
          }],

        diapers: [
          {
            "sku": "diaper,6-11,M,32,11.20",
            "name": "Подгузники",
            "size": "XS",
            "weight": "0-5 кг",
            "amount": "32 штуки",
            "discount": 0,
            "price": 1950,
            "price_with_discount": 1950,
            "count": 1,
            link: 'https://www.wildberries.ru/catalog/17995997/detail.aspx?targetUrl=XS'
          },
          {
            "sku": "diaper,6-11,M,32,11.20",
            "name": "Подгузники",
            "size": "S",
            "weight": "4-8 кг",
            "amount": "32 штуки",
            "discount": 0,
            "price": 1950,
            "price_with_discount": 1950,
            "count": 1,
            link: 'https://www.wildberries.ru/catalog/17996001/detail.aspx?targetUrl=XS'
          },
          {
            "sku": "diaper,6-11,M,32,11.20",
            "name": "Подгузники",
            "size": "M",
            "weight": "6-11 кг",
            "amount": "32 штуки",
            "discount": 0,
            "price": 1950,
            "price_with_discount": 1950,
            "count": 1,
            link: 'https://www.wildberries.ru/catalog/17996172/detail.aspx?targetUrl=XS'
          },
          {
            "sku": "diaper,6-11,M,32,11.20",
            "name": "Подгузники",
            "size": "L",
            "weight": "9-14 кг",
            "amount": "32 штуки",
            "discount": 0,
            "price": 1950,
            "price_with_discount": 1950,
            "count": 1,
            link: 'https://www.wildberries.ru/catalog/17936448/detail.aspx?targetUrl=XS'
          },

        ],

        productPanties: false,

        packAnimationNumber: 6,

        delivery: false,
      }
    },

    computed: {
      ...mapState([
        'sum', 'apiUrl', 'items', 'cartSum', 'cart', 'WBlink'
      ]),

    },

    methods: {
      ...mapMutations([
        'addToCart', 'switchProduct', 'setItems', 'calcSum'
      ]),

      goToWB() {
        location.href = this.WBlink
      },

      add() {
        if (this.sum > 0) {
          this.$router.push('/cart')

          let goodsType = []
          let goodsSize = []

          const arr = Object.values(this.cart)

          arr.forEach(item => {
            goodsType.push(`${item.name} ${item.size}`)
            goodsSize.push(item.size)
          })

          window.dataLayer.push({
            'event':'brshop',
            'eventCategory':'order',
            'eventAction':'basket',
            'eventLabel':'checkout',
            'goodsType': goodsType.join(','), // в случае, если товаров несколько указать тип через разделитель ","
            'goodsSize': goodsSize.join(','), // в случае, если товаров несколько указать тип через разделитель ","
            'price': this.cartSum
          });
        }
      },

      getItems() {
        axios.get(this.apiUrl + 'https://black-rabbit.us/api/getItems')
          .then((response) => {
            if (response.data.success) {
              const items = response.data.data

              this.setItems(items)

              this.parseItems(items)
            }
          })
          .catch((error) => {
            console.log(error);
          })
      },

      parseItems(items) {
        items.forEach(item => {
          if (item.name == 'Подгузники' && item.count > 0) {
            this.diapers.push(item)
          } else if (item.name == 'Трусики' && item.count > 0) {
            this.panties.push(item)
          }
        })
      },

      toggleDelivery() {
        this.delivery = !this.delivery
      },

      playAnimation() {
        if (this.packAnimationNumber == 1) {
          setTimeout(() => { this.packAnimationNumber = 2 }, 85)
          setTimeout(() => { this.packAnimationNumber = 3 }, 170)
          setTimeout(() => { this.packAnimationNumber = 4 }, 255)
          setTimeout(() => { this.packAnimationNumber = 5 }, 340)
          setTimeout(() => { this.packAnimationNumber = 6 }, 425)
        } else {
          setTimeout(() => { this.packAnimationNumber = 5 }, 85)
          setTimeout(() => { this.packAnimationNumber = 4 }, 170)
          setTimeout(() => { this.packAnimationNumber = 3 }, 255)
          setTimeout(() => { this.packAnimationNumber = 2 }, 340)
          setTimeout(() => { this.packAnimationNumber = 1 }, 425)
        }
      },

    },

    mounted() {
      //this.getItems()
      this.calcSum()

    },


    watch: {
      productPanties: function() {
        this.switchProduct()

        this.playAnimation()
      }
    },

    components: {
      Control, Toggle,
    }
  }
</script>

<style scoped>

</style>
